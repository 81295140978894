<template>
  <layout></layout>
</template>

<script>
import Layout from '../layouts/Main.vue';

export default {
  components: { Layout },
};
</script>
